.radius-35 {
  border-radius: 35px;
}

.card {
  cursor: pointer;
  width: 18rem;
  border-radius: 12;
}

.hover-card:hover {
  background-color: aliceblue;
  color: rgba(0, 0, 0, 0.774);
}
.hover-banner {
  color: whitesmoke;
}

.hover-banner:hover {
  opacity: 0.85;
  color: lightyellow;
}
