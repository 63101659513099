body {
  margin: 0;
  padding: 0;
  background: whitesmoke;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-blue {
  background-color: #4b39ef;
}

.pointer {
  cursor: pointer;
}
.banner {
  background: linear-gradient(45deg, #20c997 15%, #0d6efd 70%);
}
.bg-low-smoke {
  background-color: rgba(245, 245, 245, 0.61);
}
.bg-smoke {
  background-color: whitesmoke;
}

.bg-blue-low {
  background-color: rgba(1, 1, 12, 0.342);
}

.text-smoke {
  color: rgba(245, 245, 245, 0.733);
}

.radius {
  border-radius: 15px;
}

.shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.hover-btn:hover {
  color: lightblue;
}
